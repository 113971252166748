import { observable, decorate, toJS } from "mobx";
import Parse from 'parse';

import { isNumber } from 'helpers/functions';

class TaskStore {
  orders = [];
  archived = [];
  notifHistory = [];
  lane = null;

  constructor(shopStore){
    this.shopStore = shopStore;   
  }


  getOrders = async (shopId, customers) => {
    const Order = Parse.Object.extend("Order");
    const query = new Parse.Query(Order);
    const innerQuery = this.shopStore.customersQuery();
    query.matchesQuery("customer", innerQuery);
    query.notEqualTo('archived', true);

    query.include("orderStatus");
    query.include("customer");
    query.select("orderStatus.position", "description", "totalAmount", "title", "customer", "dueOn", "ticket", "payed");
    query.ascending("position");
    query.limit(5000);

    try{
      const resultOrder = await query.find();
      if(resultOrder){
        this.orders = resultOrder.map(r => r.toJSON());        
        const orderLines = await this.getOrderDetail(this.orders);
        this.createDataArray(orderLines);
        
        //this.deleteUnattachedOrdelInes(); //DEV ONLY
        return this.createBoardData();
      }
      return [];
    } catch(error){
      throw error;
    }
  }

  getOrder = async (id) => {
    const Order = Parse.Object.extend("Order");
    const query = new Parse.Query(Order);
    query.include("orderStatus");
    query.include("customer");
    query.select("orderStatus.position", "description", "totalAmount", "title", "customer", "dueOn", "ticket", "payed");

    try{
      const result = await query.get(id);
      if(result){
        const order = result.toJSON();      
        const orderLines = await this.getOrderDetail([order]);
        order.orderlines = orderLines;
        return order;
      }
      return result;
    } catch(error){
      throw error;
    }
  }

  getArchived = async () => {
    const innerQuery = this.shopStore.customersQuery();
    const Order = Parse.Object.extend("Order");
    const query = new Parse.Query(Order);
    query.matchesQuery("customer", innerQuery);
    query.limit(2000);
    query.equalTo('archived', true);
    query.include("orderStatus");
    query.include("customer");
    query.select("orderStatus.position", "description", "totalAmount", "title", "customer", "dueOn", "ticket", "payed");
    query.descending("updatedAt");

    try{
      const resultOrder = await query.find();
      if(resultOrder){
        const orderLines = await this.getOrderDetail(resultOrder.map(r => r.toJSON()));
        this.archived = resultOrder.map(r => {
          let orderObj = r.toJSON();
          orderObj.orderLines = [];
          orderLines.forEach(item => {
            let { order, ...orderLine } = item;
            if (order.objectId === orderObj.objectId) orderObj.orderLines.push(orderLine) ;
          });
          return orderObj;
        });        
        
        return this.archived;
      }
      return [];
    } catch(error){
      throw error;
    }
  }

  getOrdersByClient = async (customerId) => {
    const Order = Parse.Object.extend("Order");
    const query = new Parse.Query(Order);
    const customer = Parse.Object.fromJSON({objectId: customerId, className: "Customer"});
    query.equalTo("customer", customer);
    query.ascending("position");
    query.limit(5000);

    try{
      const result = await query.find();
      if(result.length){
        return result.map(r => r.toJSON());        
      }
      return [];
    } catch(error){
      throw error;
    }
  }

  getTicketNumber = async () => {
    const Order = Parse.Object.extend("Order");
    const query = new Parse.Query(Order);
    const innerQuery = this.shopStore.customersQuery();
    query.matchesQuery("customer", innerQuery);
    query.descending('createdAt');
    try{
      const result = await query.first();
      if(result){
        const order = result.toJSON();
        let ticket = Number(order.ticket);
        
        if(isNumber(ticket)){
          ticket = ticket+1;
          return ticket.toString();
        }
      }
      return '';
    } catch(error){
      throw error;
    }
  }

  saveOrderHistory = async (orderId, status) => {
    //status = status === 'archived' ? 'Archivé' : status;
    const OrderHistory = Parse.Object.extend('OrderHistory');
    const myNewObject = new OrderHistory();
    const order = Parse.Object.fromJSON({objectId: orderId, className: "Order"});
    myNewObject.set('order', order);
    myNewObject.set('date', new Date());
    myNewObject.set('status', status);
    myNewObject.set('notif', false);
    try {
      const result = await myNewObject.save();      
      return result;
    } catch (error) {
      console.log('Save History', error);
      throw error;
    }
  }

  getAllShopOrders = async (shopId) => {
    const Order = Parse.Object.extend("Order");
    const query = new Parse.Query(Order);
    const innerQuery = this.shopStore.customersQuery();
    query.matchesQuery("customer", innerQuery);

    query.include("orderStatus");
    query.limit(5000);

    try{
      const resultOrder = await query.find();
      if(resultOrder){
        this.orders = resultOrder.map(r => r.toJSON());        
        const orderLines = await this.getOrderDetail(this.orders);
        return this.orders;
      }
      return [];
    } catch(error){
      throw error;
    }
  }

  getOrdersHistoryPerShop = async () => {   
    const OrderHistory = Parse.Object.extend('OrderHistory');
    const query = new Parse.Query(OrderHistory);
    const ordersId = this.orders.map(order => {
      return Parse.Object.fromJSON({objectId: order.objectId, className: "Order"});
    })
    query.containedIn("order", ordersId);
    try {
      const result = await query.find();      
      return result;
    } catch (error) {
      throw error;
    }
  }


  getOrderHistory = async (filters = null) => {
    const OrderHistory = Parse.Object.extend('OrderHistory');
    const query = new Parse.Query(OrderHistory);

    if(filters){
      filters.forEach( ({ key, value})  => {
        if(key === 'order' || key === 'shop'){
          query.containedIn(key, value);
          //value = Parse.Object.fromJSON({objectId: value, className: "Order"});
        }else{
          query.equalTo(key, value);
        }
      });
    }
    query.descending("date");
    query.limit(200);

    try {
      const result = await query.find();      
      return result;
    } catch (error) {
      console.log('Save History', error);
      throw error;
    }
  }

  getNotifHistory = async () => {    
    let filter = [{
      key:'shop', value: [this.shopStore.shop.objectId]
    }, {
      key:'notif', value: true
    }];

    try{
      const result = await this.getOrderHistory(filter);
      if(result){
        return this.notifHistory = result.map(r => r.toJSON());
      }
      return [];
    } catch(error){
      throw error;
    }
  }



  deleteOrderHistory(order){
    this.getOrderHistory([{key:'order', value: [order.id]}]).then(res => {     
      if(res.length){
        Parse.Object.destroyAll(res);
      }
    })
  }

  deleteUnattachedOrdelInes = async () => {
    const OrderLine = Parse.Object.extend("OrderLine");
    const query = new Parse.Query(OrderLine);
    query.include("order");
    try{
      const result = await query.find();
      const toDelete = result.filter(r => r.get('order') === undefined) ;
      if(toDelete.length) Parse.Object.destroyAll(toDelete);
      return [];
    } catch(error){
      throw error;
    }
  }

  getOrderDetail = async (orders, parseObj = false) => {
    const ordersIds = toJS(orders).map( c => c.objectId);
    const OrderLine = Parse.Object.extend("OrderLine");
    const query = new Parse.Query(OrderLine);
    query.containedIn("order", ordersIds);
    query.select(["quantity", "objectId", "product.price", "product.name", "product.category.title", "product.category.position", "order.position"]);

    try{
      const result = await query.find();
      if(result){
        return parseObj ? result : result.map(r => r.toJSON());
      }
      return [];
    } catch(error){
      throw error;
    }
  }



  createDataArray = (orderLines) => {
    const orders = toJS(this.orders);

    const ordersIndex = orders.reduce((map, obj, index) => { 
      map[obj.objectId] = index; 
      return map; 
    }, {});

    orderLines.map( (ol) => {
      const { order, ...orderLine } = ol;
      let orderObj = orders[ordersIndex[order.objectId]];
      if(orderObj && !orderObj.orderLine){
        orderObj.orderLine = [];
      }
      orderObj.orderLine.push(orderLine);
      return ol;
    })
    this.orders.replace(orders);
    return this.orders;
  }

  createBoardData = () => {
    let lanes = [];
    let orders = toJS(this.orders);
    const noOrderStatus = orders.filter(o => !o.orderStatus)
    
    this.shopStore.orderStatus.forEach((status, laneIndex) => {      
      let { objectId, title, trigger, alias } = status;
      lanes.push({ id: objectId, title, trigger, alias, cards: [] });
      let ordersFound = orders.filter(order => order.orderStatus && (order.orderStatus.objectId === status.objectId));
      if(ordersFound.length){       
        lanes[laneIndex].cards = ordersFound.map((order, i) => this.createCardData(order, objectId) );        
      }
    })

    if(noOrderStatus.length){
      let cards = noOrderStatus.map((obj, i) => {
        return this.createCardData(obj, 'no_status')
      })      
      lanes.push({ id: 'no_status', title: 'Pas classé', cards });
    }

    let board = { lanes };
    return board;
  }

  createCardData = (obj, laneId) => {
    let { objectId: id, customer: {lastName, firstName, email, mobile, objectId}, dueOn, totalAmount, orderLine, ticket, createdAt, description, title, position, payed } = obj;
    totalAmount = totalAmount || undefined;
    dueOn = dueOn || undefined;

    let products = [];    
    if(orderLine && orderLine.length){
      products = orderLine.map( l => { 
        if(l.product){
          return { label:l.product.name, value: l.product.objectId, orderLineId: l.objectId} ;
        }else{
          return { label:'[effacé]', value: undefined, orderLineId: l.objectId} ;
        }
      });
    }   
    
    
    return {
      id, title, description, totalAmount, client: { lastName, firstName, email, mobile, objectId }, products, laneId, position, ticket, payed,
      dueOn: (dueOn && dueOn.iso) ? new Date(dueOn.iso) : null, 
      createdAt: new Date(createdAt), 
    }
  }


  saveTask = async (values, action = 'create') => {
    
    const laneId = this.lane.id;
    const Order = Parse.Object.extend("Order");
    const customerObj = Parse.Object.fromJSON({objectId: values.customerId, className: "Customer"});
    const orderStatus = Parse.Object.fromJSON({objectId: laneId, className: "OrderStatus"});
    let order = (action === 'update') ? Parse.Object.fromJSON({objectId: values.orderId, className: "Order"}) : new Order();

    const totalAmount = values.totalAmount ? parseInt(values.totalAmount) : undefined ;

    order.set('position', this.lane.cardPosition)
    order.set('orderStatus', orderStatus)
    order.set('title', values.title)
    order.set('description', values.description)
    order.set('totalAmount', totalAmount)
    order.set('dueOn', values.dueOn ? new Date(values.dueOn) : null)
    order.set('customer', customerObj);
    order.set('archived', false);
    order.set('ticket', values.ticket);
    order.set('payed', values.payed);

    try {
      const result = await order.save();     
      let orderSaved = result.toJSON();
      
      const orderLine = await this.saveOrderLine(result.toPointer(), values.products);      
      const orderLineObj = orderLine.map( l => l.toJSON())
      orderSaved.orderLine = orderLineObj;
      orderSaved.customer = values.customer;
      if(action === 'update'){
        const orders = this.orders.map( o => orderSaved.objectId === o.objectId ? orderSaved : o );
        this.orders.replace(orders);
      }else{
        this.orders.push(orderSaved);
      }
      return this.createCardData(orderSaved, laneId );
    } catch (error) {
      console.log('Save Task', error);
      throw error;
    }

  }


  saveOrderLine = async (order, products) => {
    
    const OrderLine = Parse.Object.extend("OrderLine");
    const saveArr = [];
    const destroyArr = [];
    try {
      const orderDetail = await this.getOrderDetail([order], true);
      orderDetail.map(res => {
        let match = products.some(p => (p.orderLineId === res.id));
        if(!match){
          destroyArr.push(res);
        }
        return res;
      })
      products.forEach(item => {
        let product = Parse.Object.fromJSON({objectId: item.value, className: "Product"});
        let orderLine;
        if(!item.orderLineId){
          //create
          orderLine = new OrderLine();
        }else{
          //update
          orderLine = Parse.Object.fromJSON({objectId: item.orderLineId, className: "OrderLine"});
        }
        orderLine.set('product', product);
        orderLine.set('order', order);
        saveArr.push(orderLine);
    });
      if(destroyArr.length){ Parse.Object.destroyAll(destroyArr); }
      return saveArr.length ? Parse.Object.saveAll(saveArr) : [];
      
    } catch (error) {
      console.log('saveOrderLine', error);
      throw error;
    }
    
  }


  deleteOrder = async (objectId, orderStatusId) => {    
    const className = "Order";
    const orderIndex = this.orders.findIndex(o => o.objectId === objectId);
    const order = Parse.Object.fromJSON({objectId, className});
    const siblings = this.orders.filter(o => (o.orderStatus && (o.orderStatus.objectId === orderStatusId)) && o.objectId !== objectId );
    let orderLine;
    if(this.orders[orderIndex] && this.orders[orderIndex].orderLine){
      orderLine = this.orders[orderIndex].orderLine;
    }
    
    try {
      await this.deleteOrderLine(orderLine);
      const res = await order.destroy();
      this.orders.splice(orderIndex, 1);
      this.updateOrderPosition(siblings);
      this.deleteOrderHistory(order);
      return res;
    } catch (error) {
      throw error;
    }
  }

  deleteShopOrders = async () => {
    const orders = await this.getAllShopOrders();
    
    if(!this.orders) return true;
    let arrayToDestroy = [];
    const ordersHistory = await this.getOrdersHistoryPerShop();
    arrayToDestroy = [...ordersHistory];

    this.orders.forEach( ({objectId, orderLine}) => {
      const order = Parse.Object.fromJSON({objectId, className: "Order"});
      arrayToDestroy.push(order);
      orderLine.forEach( ({objectId})=> {
        const orderLine = Parse.Object.fromJSON({objectId, className: "OrderLine"});
        arrayToDestroy.push(orderLine);
      });
    });
    try {
      const res = await Parse.Object.destroyAll(arrayToDestroy);
      
      return res;
    } catch (error) {
      throw error;
    }

    
    
  }

  updateOrderPosition = async (orders) => {
    let arrayToSave = [];
    orders.forEach( (item) => {
      const orderToSave = Parse.Object.fromJSON({objectId: item.objectId, className: "Order"});
      orderToSave.set('position', item.position);
      if(item.orderStatus){
        const objectId = item.orderStatus.objectId || item.orderStatus;
        const orderStatus = Parse.Object.fromJSON({objectId, className: "OrderStatus"});
        let orderStatusObj = this.shopStore.orderStatus.find(o => o.objectId === objectId);
        if(orderStatusObj && orderStatusObj.alias === 'pickedup'){
          orderToSave.set('archived', true);
        }
        orderToSave.set('orderStatus', orderStatus);
      }
      arrayToSave.push(orderToSave);
    });
    
    return Parse.Object.saveAll(arrayToSave);
  }

  deleteOrderLine = async (orderLine) => {
    if(!orderLine) return;
    let arrayToDestroy = [];
    orderLine.forEach( ({objectId})=> {
      const orderLine = Parse.Object.fromJSON({objectId, className: "OrderLine"});
      arrayToDestroy.push(orderLine);
    });
    return Parse.Object.destroyAll(arrayToDestroy);
  }


  archiveOrder = async (objectId, state) => {
    const order = Parse.Object.fromJSON({objectId, className: "Order"});
    order.set('archived', state);
    let orderArchived = this.archived.find(o => o.objectId === objectId);
    let status = state ? 'Archivé' : orderArchived.orderStatus.title
    this.saveOrderHistory(objectId, status);
    return order.save();
  }

  findOrder = (objectId) => {
    return this.orders.find( o => o.objectId === objectId );
  }

  findCustomerByOrder = (objectId) => {
    const order = this.findOrder(objectId);
    if(order && order.customer) return order.customer;
    return null;
  }


}


export default decorate( TaskStore, {
  orders: observable,
  archived: observable,
  notifHistory: observable,
});
