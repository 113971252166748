import * as Yup from 'yup';

const numMessage = 'Renseigner un numéro (sans espace, point ou virgule)';
const intMessage = 'Renseigner un montant (sans espace, point ou virgule)';
const posMessage = 'Renseigner un chiffre positif';
const labels = {
  firstname: 'Prénom',
  lastName: 'Nom',
  title: 'Titre',
  name: 'Titre',
  customerId: 'Client',
  ticket: 'Ticket',
  content: 'Template',
  username: `Nom d'utilisateur`,
  password: `Mot de passe`,
  passwordConfirm: `Confirmer`,
  price: `Prix`,
  mobile: `Téléphone`,
  alias: `Alias`
}

class Validation {

  yupString = (key) => {    
    let label = key ? labels[key] : '';
    return Yup.string().label(label).min(3);
  } 

  get dueOn(){
    return Yup.date().label("Deadline");
  } 

  get moreInfo(){
    return Yup.boolean();
  } 

  get moreInfoSms(){
    return Yup.boolean();
  } 

  get moreInfoEmail(){
    return Yup.boolean();
  } 

  get totalAmount(){
    return Yup.number().typeError(intMessage).positive(posMessage);
  } 

  get email(){     
    return Yup.string().email("Renseigner un Email valide").label("Email");
  } 

  get sendBy(){     
    return Yup.object().shape({
      sms: Yup.boolean(),
      email: Yup.boolean()
    })
  } 

  get mobile(){     
    return Yup.number().typeError(numMessage).positive(posMessage).test('len', 'Renseigner un numéro à 6 chiffres', val => !val || (val && val.toString().length === 6));
  } 

  get orderStatus(){
    return Yup.array()
    .of(
      Yup.object().shape({
        title: this.yupString('title').required(), // these constraints take precedence
      })
    )
    .label('statut')
    .required() // these constraints are shown if and only if inner constraints are satisfied
    .min(3, 'Il faut un minimum de 3 états')
  }

  get products(){
    return Yup.array()
    .of(
      Yup.object().shape({
        name: this.yupString('name').required(), // these constraints take precedence
        price: Yup.number().typeError(intMessage).positive(posMessage).nullable().transform((cv, ov) => {
          return ov === '' ? undefined : cv;
        })
      })
    )
    .label('Produits')
  }

  get messageTpl(){
    return Yup.array()
    .of(
      Yup.object().shape({
        content: this.yupString('content').required('Ce champs est requis')
          .test('len', 'Le champs ${path} est requis', function( val ) {
            const item = this.parent;
            //const index = parseInt(this.path.split("[")[1].split("]")[0], 10);
            if(item && item.type && item.type === 'sms' && val && val.toString().length > 200){
              return this.createError({path: this.path, message: "Le champs SMS est limité à 200 caractères"});
            }else{
              return true;
            }
          })
      })
    )
    .label('Template')
  }

  get labels(){
    return labels;
  }

  passwordConfirmConditional = (condition, required) => (
    Yup.string()
      .when(
        condition, {
          is: (val) => (val !== undefined ) === true,
          then: this.rule('passwordConfirm', required).oneOf([Yup.ref('password')], `Le mot de passe doit être le même`)
        }
      )
  )

  emailConditional = (condition, required) => (
    Yup.string()
      .when(
        condition, {
          is: true,
          then: this.rule('email', required)
        }
      )    
  )

  mobileConditional = (condition, required) => (
    Yup.number().when(
      condition, {
        is: true,
        then: this.rule('mobile', required, required ? 'Renseigner un numéro de mobile': undefined )
      }
    ) 
  )       

  rule = (key, required = false, message) => {    
    let result =  this[key] ? this[key] : this.yupString(key) ;   
    return required ? result.required(message): result;
  }

  shape = (object) => ( Yup.object().shape(object) )

  toastSettings = {
    position: 'top-right',
    autoClose: true,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
  }

}

export const validation = new Validation();