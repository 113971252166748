import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'mobx-react';
import createBrowserHistory from 'history/createBrowserHistory';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import Loadable from 'react-loadable';
import PrivateRoute from 'react-private-route';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import { setLocale } from 'yup';

import { RootStore } from 'stores';
import './App.scss';

setLocale({
  mixed: { 
    // eslint-disable-next-line
    required: 'Le champ "${label}" est requis'
  },
  string: {
    // eslint-disable-next-line
    min: 'Ce champs doit faire au moins ${min} lettres'
    
  },
  number: {
    // eslint-disable-next-line
    min: 'Ce champs doit faire au moins ${min} chiffres', max: 'Ce champs doit faire au maximum ${max} chiffres'
  },
});


const routingStore = new RouterStore();
const stores = new RootStore();
stores.navigation = routingStore;
const browserHistory = createBrowserHistory();
const history = syncHistoryWithStore(browserHistory, routingStore);


const loading = () => <div className="animated fadeIn pt-3 text-center">Chargement...</div>;

// Containers
const DefaultLayout = Loadable({
  loader: () => import('./containers/DefaultLayout'),
  loading
});

// Pages
const Login = Loadable({
  loader: () => import('./views/Pages/Login'),
  loading
});

/*const Register = Loadable({
  loader: () => import('./views/Pages/Register'),
  loading
});*/

const Page404 = Loadable({
  loader: () => import('./views/Pages/Page404'),
  loading
});

const Page500 = Loadable({
  loader: () => import('./views/Pages/Page500'),
  loading
});

class App extends Component {
  componentWillMount(){
    this._bootstrapUser()   
  }

  async _bootstrapUser(){
    stores.authStore.setIsFetchingUserInfos(true);
    const user = await stores.authStore.getCurrentUser();
    
    //wait for current user to fetch shop
    if( user && user.role === 'Shop Admin'){
      await stores.shopStore.getShop(stores.authStore.currentUserPointer);
    }else if( user && stores.shopStore.shop ){
      stores.shopStore.resetShop();
    }
    stores.authStore.setIsFetchingUserInfos(false);
  }

  render() {

    return (
      <Router history={history}>
        <Provider {...stores}>
          <Switch>
            <Route exact path="/login" name="Login Page" component={Login} />
            {/*<Route exact path="/register" name="Register Page" component={Register} />*/}
            <Route exact path="/404" name="Page 404" component={Page404} />
            <Route exact path="/500" name="Page 500" component={Page500} />
            <PrivateRoute isAuthenticated={stores.authStore.isAuthenticated} path="/" name="Home" component={DefaultLayout} />
          </Switch>
        </Provider>
      </Router>
    );
  }
}

export default compose(
	observer,
)( App );