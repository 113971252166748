import { observable, action, decorate } from "mobx";

class BreadcrumbStore {
  
  routes = [];
  name = null;
  index = null;
  
  setRoutes(routes){
    this.routes = routes;
  }

  setRouteName(path, name){
    this.name = name;
    this.index = this.routes.findIndex(r => r.path === path);
  }

  getRouteName(match){
    const route = this.routes.find(r => r.path === match.path);
    return route ? route.name : null;
  }

  getRouteClass(match){
    const route = this.routes.find(r => r.path === match.pathname);
    return route && route.class ? ['main', route.class] : 'main';
  }

  reset(){
    this.name = null;
    this.index = null;
  }

}

export default decorate( BreadcrumbStore, {
  setRouteName: action,
  name: observable,
  index: observable
});
