import { observable, action, decorate } from "mobx";

class LayoutStore {
  
  mobileNavVisibility = false;

  setMobileNavVisibility(state){
    this.mobileNavVisibility = state;
  }

}

export default decorate( LayoutStore, {
  setMobileNavVisibility: action,
  mobileNavVisibility: observable
});
