import { observable, action, decorate } from "mobx";
import Parse from 'parse';
import { toast } from 'react-toastify';


import { validation } from 'helpers/validation';


const actionMsg = {
   202: `Un compte existe déjà pour ce nom d'utilisateur.`,
   error : "Une erreure s'est produite",
   success : "Action terminé avec succès"
}


class AuthStore {
  errorLogin = false;
  isFetchingUserInfos = false;
  isAuthenticated = null;
  currentUser = null;
  currentUserPointer = null;
  submitting = false;
  roles = [];
  

  getCurrentUser = async () => {
    try{
      let user = await Parse.User.currentAsync();
      if (user) {
        this.isAuthenticated = true;
        const role = await this.getUserInfos(user);
        this.setUser(user, role);
        return this.currentUser;
      }
    } catch(error){
      throw error;
    } 
  }

  setUser = (user, role) => {
    this.currentUserPointer = user.toPointer();
    this.currentUser = user.toJSON();
    this.currentUser.role = role;
    this.isAuthenticated = true;
  }

  setIsFetchingUserInfos = (val) => {
    this.isFetchingUserInfos = val;
  }

  getUserInfos = async (user) => {
    try{
      const query = new Parse.Query(Parse.Role).equalTo('users', user);
      const role = await query.first();
      const roleName = role.getName();
      const roleChildren = await role.relation('roles').query().find();
      this.roles = roleChildren.map(r => r.getName());
      this.roles.push(roleName)
      return roleName;
    } catch(error){
      throw error;
    }
  }

  saveUserInfos = async (data) => {
    const user = Parse.Object.fromJSON(this.currentUserPointer);
    try{
      const res = await user.save(data);
      if(res){
        this.currentUser = res.toJSON()
      }
      return this.currentUser;
    } catch(error){
      throw error;
    }
  }

  createUser = async (data) => {
    const user = new Parse.User();
    user.setACL(new Parse.ACL());
    try{
      const res = await user.save(data);
      return res;//.toJSON();
    } catch(error){     
      console.log('createUser', error.code);
      const message = actionMsg[error.code] ? actionMsg[error.code] : actionMsg.error
      toast.error(message, validation.toastSettings); 
      throw error;
    }
  }

  addUserRole(user, roleName){
    var rolesQuery = new Parse.Query(Parse.Role);
    rolesQuery.equalTo('name', roleName);
    return rolesQuery.first({useMasterKey:true})
      .then((roleObject) => {
        roleObject.getUsers().add(user);
        return roleObject.save(null, {useMasterKey:true});
      });
  }

  login = async ({username, password}) => {
    this.isFetchingUserInfos = true;
    try {
      const user = await Parse.User.logIn(username, password);
      if (user) {
        const role = await this.getUserInfos(user);
        this.setUser(user, role);
        return this.currentUser;
      }
    } catch (error){     
      throw error;
    } 


    /*Parse.User.logIn(username, password).then(() => {

    }).catch((err) => {
      this.errorLogin = true;
    }).finally( () => this.submitting = false )*/
  }

  logout = () => {
    Parse.User.logOut().then(() => {
      this.isAuthenticated = null;
      this.currentUser = null;
      this.currentUserPointer = null;
      this.setIsFetchingUserInfos(false);
    }).catch( (err) => {
      this.errorLogout = true;
    });
  }
  

  resetPassword = (email) => {
    return Parse.User.requestPasswordReset(email);
  }
}

export default decorate( AuthStore, {
  logout: action,
  getCurrentUser: action,
  setUser: action,
  isAuthenticated: observable,
  errorLogin: observable,
  isFetchingUserInfos: observable,
  currentUser: observable,
  userCheck: observable
});
