import latinize from 'latinize';
import slugify from 'slugify';

export function generatePagination(activePage, totalPages, margin = 1) {
  const previousPage = (activePage - 1) > 0 ? (activePage - 1) : 1
  const nextPage = (activePage + 1) > totalPages ? totalPages : (activePage + 1)
  const gap = 1 + 2 * margin
  const numPagesShow = 4 + gap
  const pagination = [
    //{ active: false, value: 1, text: '<<' },
    { active: false, value: previousPage, text: 'previous' },
  ]
  if (totalPages > numPagesShow) {
    if (activePage >= gap) {
      pagination.push({ active: false, value: 1, text: '1' })
      if (activePage > gap) {
        pagination.push({ active: false, text: '...' })
      }
    }
    for (let i = -margin; i < 2 * margin; i += 1) {
      const page = activePage + i
      if (page > 0 && page <= totalPages) {
        pagination.push({
          active: activePage === page,
          value: page,
          text: `${page}`,
        })
      }
    }
    if (totalPages - activePage > margin) {
      if (totalPages - activePage - 1 > margin) {
        pagination.push({ active: false, text: '...' })
      }
      pagination.push({ active: false, value: totalPages, text: `${totalPages}` })
    }
  } else {
    for (let i = 1; i <= totalPages; i += 1) {
      pagination.push({
        active: activePage === i,
        value: i,
        text: `${i}`,
      })
    }
  }
  pagination.push({ active: false, value: nextPage, text: 'next' })
  //pagination.push({ active: false, value: totalPages, text: '>>' })
  return pagination
}

const isString = str => typeof str === 'string' || str instanceof String

const isArray = obj => Array.isArray(obj)

const isObject = obj => obj && typeof obj === 'object' && obj.constructor === Object

const emptyNumberField = val => ( typeof val !== undefined && val === '') ? null : val ;

const isEmpty = (obj) => {
  if (isArray(obj)) return !obj.length
  if (isObject(obj)) return !Object.keys(obj).length
  return false
}

const isFunction = fn => typeof fn === 'function'

const isNumber = num => typeof num === 'number' && Number.isFinite(num)

const isUndefined = undef => typeof undef === 'undefined'

const capitalize = str => (isString(str) ? str[0].toUpperCase() + str.substring(1) : '')

const reorderArray = (array, oldIndex, newIndex) => {
  const movedItem = array.find((item, index) => index === oldIndex);
  const remainingItems = array.filter((item, index) => index !== oldIndex);
  const reorderedItems = [
    ...remainingItems.slice(0, newIndex),
    movedItem,
    ...remainingItems.slice(newIndex)
  ];
return reorderedItems;
}

const currencyFormatter = new Intl.NumberFormat('fr-FR', {
  style: 'currency',
  currency: 'XPF',
  minimumFractionDigits: 0
})

const currencyString = (number) => currencyFormatter.formatToParts(number).map(({type, value}) => { 
  switch (type) {
    case 'currency': return 'F'; 
    default : return value; 
  } 
}).reduce((string, part) => string.replace(/[^\w\%C2%A0!?]/g,' ') + part);


const toLatineLowerCase = (str) => {
  return latinize(str.toLowerCase());
}

const slugifyLower = (str) => {
  return slugify(str, {
    replacement: '',    // replace spaces with replacement
    remove: null,        // regex to remove characters
    lower: true          // result in lower case
  })
  
}

export {
  isString, isArray, isObject, isEmpty, isFunction, isNumber, isUndefined, capitalize, reorderArray, emptyNumberField, currencyFormatter, currencyString, toLatineLowerCase, slugifyLower
}