import { observable, action, decorate, toJS } from "mobx";
import Parse from 'parse';
import { isNumber } from 'helpers/functions';


const orderStatusList = [
  {
    title: 'Déposé', 
    alias: 'droped'
  }, {
    title: "Terminé", 
    alias: "done"
  }, {
    title: "Notifié", 
    alias: "sent"
  }, {
    title: "Récupéré", 
    alias: "pickedup"
  }
];

const messageList = [
  {
    title: 'SMS (Terminé)', 
    type: 'sms_done',
    content: 'Bonjour [prenom] [nom],\nVotre commande est prete !\nLe montant est de [montant] à récupérer aux horaires habituels.'
  }, {
    title: "SMS (Déposé)", 
    type: "sms_droped",
    content: 'Votre commande [ticket] est bien enregistrée'
  }, {
    title: "Email", 
    type: "email",
    content: 'Votre commande est prete ! [montant]'
  }
];

class ShopStore {
  shop = null;
  shops = [];
  shopPointer = null;
  catPointer = null;
  products = [];
  customers = [];
  customer = null;
  orderStatus = [];
  categories = [];
  messageTpl = [];

  getShop = async (userPointer) => {   
    const Shop = Parse.Object.extend("Shop");
    const query = new Parse.Query(Shop);
    query.equalTo("admin", userPointer);
    return this.getShopCall(query);
  }

  getShopByName = async (name) => {
    const Shop = Parse.Object.extend("Shop");
    const query = new Parse.Query(Shop);
    query.equalTo("alias", name);
    return this.getShopCall(query);
  }

  getShopCall = async (query) => {   
    query.include("admin");
    try{
      const result = await query.first({useMasterKey: true});
      if(result){
        this.shopPointer = result.toPointer();
        this.shop = result.toJSON();
        await this.fetchShopDetail();
      }
      return this.shop;
    } catch(error){
      throw error;
    }
  }


  getAllShops = async () => {
    const Shop = Parse.Object.extend("Shop");
    const query = new Parse.Query(Shop);
    try{
      const result = await query.find();
      if(result){
        this.shops = result.map(r => r.toJSON());
      }
      return this.shops;
    } catch(error){
      throw error;
    }
  }

  fetchShopDetail = async () => {
    const resultCategory = this.getShopCategory();
    const resultOrderStatus = this.getOrderStatus();
    const resultMessageTpl = this.getMessageTpl();
    return Promise.all([resultCategory, resultOrderStatus, resultMessageTpl]);
  }

  getShopCategory = async () => {
    const Category = Parse.Object.extend("Category");
    const query = new Parse.Query(Category);
    query.equalTo("shop", this.shopPointer);
    try{
      const result = await query.find();
      if(result.length){
        result.forEach(item => (item.get('title') === 'default') ? this.catPointer = item.toPointer() : null);
        this.categories = result;
        await this.getShopProducts(result);
      }
      return this.categories;
    } catch(error){
      throw error;
    }
  }

  getOrderStatus = async () => {   
    const shopId = this.shopPointer.objectId;   
    const OrderStatus = Parse.Object.extend("OrderStatus");
    const query = new Parse.Query(OrderStatus);
    query.containedIn("shop", [shopId]);
    query.ascending("position");
    query.select(["position", "title", "trigger", "alias"]);
    return this.tryFind(query, 'orderStatus');
  }

  saveShop = async (data) => {   
    const shop = Parse.Object.fromJSON(this.shopPointer);
    try{
      const result = await shop.save(data);
      if(result){
        this.shop = result.toJSON();
      }
      return this.shop;
    } catch(error){
      throw error;
    }
  }

  createShop = async (user, data) => {   
    const Shop = Parse.Object.extend('Shop');
    const newShopObject = new Shop();
    const groupACL = this.setCustomACL(user);  
    
    newShopObject.setACL(groupACL);
    newShopObject.set("admin", [user.toPointer()] )

    try{
      const result = await newShopObject.save(data);
      return result;
    } catch(error){
      throw error;
    }
  }


  deleteShop = async (shopAlias) => {
    try{
      const newShop = await this.getShopByName(shopAlias);
      const customers = await this.getShopCustomers(5000, 0, true);
      const users = this.shop.admin.map(user => Parse.Object.fromJSON({ className: "_User", objectId: user.objectId }))

      let parseObjectsToDelete = [...this.categories, ...customers];


      const objectsDeleted = await Promise.all([
        Parse.Object.destroyAll(parseObjectsToDelete),
        this.deleteObjects(this.orderStatus, 'OrderStatus'),
        this.deleteObjects(this.products, 'Product'),
        this.deleteObjects([this.shop], 'Shop'),
        Parse.Object.destroyAll(users, { useMasterKey: true})
      ]);
      
      return objectsDeleted;
  
    }catch(error){
      throw error;
    }

  }


  setCustomACL(user){
    const groupACL = new Parse.ACL();
    groupACL.setReadAccess(user, true);
    groupACL.setWriteAccess(user, true);
    groupACL.setRoleReadAccess('Super Admin', true);
    groupACL.setRoleWriteAccess('Super Admin', true);
    return groupACL;
  }

  createShopSettings = async (shop, user) => {
    const groupACL = this.setCustomACL(user);  
    const shopPointer = shop.toPointer();
    const Category = Parse.Object.extend('Category');
    const OrderStatus = Parse.Object.extend('OrderStatus');
    const Message = Parse.Object.extend('Message');

    const newCatObject = new Category();
    newCatObject.set("shop", shopPointer );
    newCatObject.set('title', 'default');

    let newStatusObjects = [];
    for (var i = 0; i < orderStatusList.length; i++) {
      const newStatusObject = new OrderStatus();
      let item = orderStatusList[i];
      newStatusObject.set("shop", shopPointer );
      newStatusObject.set('title', item.title);
      newStatusObject.set('alias', item.alias);
      newStatusObject.set('trigger', true);
      newStatusObject.set('position', i);
      newStatusObject.setACL(groupACL);
      newStatusObjects.push(newStatusObject);
    }

    let messageObjects = [];
    for (var j = 0; j < messageList.length; j++) {
      const messageObject = new Message();
      let item = messageList[j];
      messageObject.set("shop", shopPointer );
      messageObject.set('title', item.title);
      messageObject.set('type', item.type);
      messageObject.set('content', item.content);
      messageObject.set('sms_droped', false);
      messageObject.set('position', j);
      messageObject.setACL(groupACL);
      messageObjects.push(messageObject);
    }

    try{
      const result = await Promise.all([newCatObject.save(), Parse.Object.saveAll(newStatusObjects), Parse.Object.saveAll(messageObjects)]);
      return result;
    } catch(error){
      console.log('createShopSettings', error.code);
      throw error;
    }
  }

  saveMessage = (item, index, className) => {    
    const objToSave = Parse.Object.fromJSON({objectId: item.objectId, className});
    objToSave.set('content', item.content);
    return objToSave;
  }

  saveProduct = (item, index, className) => {    
    const objToSave = Parse.Object.fromJSON({objectId: item.objectId, className});
    objToSave.set('position', index);
    objToSave.set('price', item.price);
    objToSave.set('name', item.name);
    objToSave.set('category', this.catPointer);
    return objToSave;
  }

  saveOrderStatus = (item, index, className ) => {
    const objToSave = Parse.Object.fromJSON({objectId: item.objectId, className});
    objToSave.set('position', index);
    objToSave.set('title', item.title);
    objToSave.set('shop', this.shopPointer);
    return objToSave;
  }

  getMessageTpl() {
    const Message = Parse.Object.extend("Message");
    const query = new Parse.Query(Message);
    query.ascending("position");
    query.equalTo("shop", this.shopPointer);
    return this.tryFind(query, 'messageTpl');
  }

  getShopProducts(cats){
    const Product = Parse.Object.extend("Product");
    const query = new Parse.Query(Product);
    const match = cats.map(c => c.toPointer())
    query.ascending("position");
    query.containedIn("category", match);
    return this.tryFind(query, 'products');
  }

  getShopCustomers = async (limit, page = 0, json = null) => {
    if(!this.shopPointer) return null;
    const query = this.customersPagerQuery(limit, page);    
    //return this.tryFind(query, 'customers');
    try{
      const result = await query.find({useMasterKey: true});      
      return !json ? result.map(r => r.toJSON()) : result;
    } catch(error){
      throw error;
    }
  }

  getShopCustomersCount = async () => {
    if(!this.shopPointer) return null;
    const query = this.customersPagerQuery(5000);
    return this.tryCount(query, 'customers');
  }

  searchShopCustomers = async (value) => {
    if(!this.shopPointer) return null;
    const query = this.customersSearchQuery(value);
    try{
      const result = await query.find();      
      return result.map(r => r.toJSON());
    } catch(error){
      throw error;
    }
  }

  customersPagerQuery(limit, page = 0){
    const Customer = Parse.Object.extend("Customer");
    const query = new Parse.Query(Customer);
    query.limit(limit);
    query.equalTo("shop", this.shopPointer);
    query.ascending("lastName");
    query.skip(page * limit);
    return query;
  }

  customersQuery(){
    const Customer = Parse.Object.extend("Customer");
    const query = new Parse.Query(Customer);
    query.equalTo("shop", this.shopPointer);
    return query;
  }

  customersSearchQuery(value){
    const Customer = Parse.Object.extend("Customer");
    let query ;
    if(!isNaN(value)){     
      value = Number(value);
      const mobile = new Parse.Query(Customer);
      mobile.equalTo("mobile", value);
      query = Parse.Query.or(mobile);
    }else{
      const lastName = new Parse.Query(Customer);
      const firstName = new Parse.Query(Customer);
      const email = new Parse.Query(Customer);
      lastName.matches("lastName", value, 'i');
      firstName.matches("firstName", value, 'i');
      email.matches("email", value, 'i');
      query = Parse.Query.or(lastName, firstName, email);
    }
    query.equalTo("shop", this.shopPointer);
    query.ascending("lastName");
    return query;
  }



  saveCustomer(values, type){
    let customerToSave;
    let { objectId, shop, ...customer } = toJS(values);
    customer.mobile = isNumber(customer.mobile) ? customer.mobile : null;
    
    if(type === 'update'){
      customerToSave = Parse.Object.fromJSON({ objectId, className: "Customer" });
    }else if(type === 'create'){
      let Customer = Parse.Object.extend("Customer");
      customerToSave = new Customer();
      customerToSave.set("shop", this.shopPointer);
    }else{
      return;
    }
    return customerToSave.save(customer);
  }

  setCustomer = (res, type) => {
    const customer = res.toJSON();
    let newArr = [];

    if(type === 'update'){
      newArr = this.customers.map( r => r.objectId === customer.objectId ? customer : r );
    }else if(type === 'create'){
      newArr = this.customers.push(customer);
    }
    if(newArr.length){
      this.customers.replace(newArr);
    }
  }

  saveCustomerFromTask = async (data) => {
    let Customer = Parse.Object.extend("Customer");
    let customer = new Customer();
    customer.set("shop", this.shopPointer);

    try {
      const res = await customer.save(data);
      if(res){
        const data = this.customers;
        data.push(res.toJSON());
        this.customers.replace(data);
        return res.toJSON();
      }
      return null;
    } catch (error) {
      throw error;
    }
  }

  deleteCustomer = async (objectId, index) => {
    const customer = Parse.Object.fromJSON({ objectId, className: "Customer" });
    try {
      const res = await customer.destroy();
      this.customers.splice(index, 1);
      return res;
    } catch (error) {
      throw error;
    }
  }

  filterCustomer = (id) => {
    return this.customer = this.customers.find( r => r.objectId === id);
  }
 
  resetShop(){
    this.shop = null;
    this.shopPointer = null;
    this.customers = [];
  }

  resetCustomer(){
    this.customer = null;
  }




  /*
  * HELPERS
  */

  findCustomer = (objectId) => {
    return this.customers.find( o => o.objectId === objectId );
  }

  findStatusBy = (prop, value) => {
    return this.orderStatus.find( o => o[prop] === value );
  }

  
  saveObjects = (list, className, name) => {
    let arrayToSave = [];
    const functionName = `save${className}`;
    list.forEach( (item, index) => {
      const objToSave = this[functionName](item, index, className);
      arrayToSave.push(objToSave);
    });
    return this.trySave(arrayToSave, name);
  }


  deleteObjects = (list, className) => {
    let arrayToSave = [];
    list.forEach( (item) => {
      const objToSave = Parse.Object.fromJSON({objectId: item.objectId, className});
      arrayToSave.push(objToSave);
    });
    return Parse.Object.destroyAll(arrayToSave);
  }

  trySave = async (arrayToSave, name) => { 
    try{
      const result = await Parse.Object.saveAll(arrayToSave);     
      if(result){
        const data = result.map(r => r.toJSON());
        this[name].replace(data);
      }      
      return this[name];
    } catch(error){
      throw error;
    }
  }

  tryFind = async (query, name) => { 
    try{
      const result = await query.find();      
      return this[name] = result.map(r => r.toJSON());
    } catch(error){
      throw error;
    }
  }

  tryCount = async (query, name) => { 
    try{
      const count = await query.count();      
      return count;
    } catch(error){
      throw error;
    }
  }

}

export default decorate( ShopStore, {
  shop: observable,
  shops: observable,
  products: observable,
  customers: observable,
  customer: observable,
  categories: observable,
  orderStatus: observable,
  messageTpl: observable,
  resetShop: action,
  resetCustomer: action,
  filterCustomer: action,
  getShop: action,
  setCustomer: action,
  getShopProducts: action
});
