import AuthStore from "./AuthStore";
import LayoutStore from "./LayoutStore";
import ShopStore  from "./ShopStore";
import BreadcrumbStore  from "./BreadcrumbStore";
import TaskStore  from "./TaskStore";
import NotifStore  from "./NotifStore";
import MessageStore  from "./MessageStore";

export class RootStore {
  constructor() {
    this.authStore = new AuthStore();
    this.layoutStore = new LayoutStore();
    this.shopStore = new ShopStore();
    this.taskStore = new TaskStore(this.shopStore);
    this.notifStore = new NotifStore(this.shopStore, this.taskStore);
    this.pathStore = new BreadcrumbStore();
    this.messageStore = new MessageStore();
  }
}
