import { observable, action, decorate, toJS } from "mobx";
import Parse from 'parse';
import { currencyString } from 'helpers/functions';

class NotifStore {

  routes = [];
  sending = false;
  customer = {};
  order = {};
  sentStatus = null ;
  date = null;

  constructor(shopStore, taskStore){
    this.shopStore = shopStore;
    this.taskStore = taskStore;   
  }
  
  async send(order, customSMS, state){
    this.date = new Date();
    this.customer = toJS( this.taskStore.findCustomerByOrder(order.id) );
    
    this.order = order;
    const sentStatus = this.shopStore.findStatusBy('alias', 'sent');
    const messageTpl = toJS( this.shopStore.messageTpl );
    const promises = [];
    this.sentStatus = sentStatus ? { objectId: sentStatus.objectId, title: sentStatus.title } : null;

    if(state === 'done'){
      const smsTpl = messageTpl.find(tpl => tpl.type === 'sms_done');
      const emailTpl = messageTpl.find(tpl => tpl.type === 'email');
  
      if(this.customer.sendBy.sms && this.customer.mobile){
        promises.push(this.sendSMS(smsTpl.content, customSMS));
      }
  
      if(this.customer.sendBy.email && this.customer.email){
        promises.push(this.sendEmail(emailTpl.content));
      }
    }

    if(state === 'droped'){
      const smsTpl = messageTpl.find(tpl => tpl.type === 'sms_droped');
      this.sentStatus = state;
      if(this.customer.sendBy.sms && this.customer.mobile){
        promises.push(this.sendSMS(smsTpl.content, customSMS));
      }
  
    }
    return Promise.all(promises);   
  }

  async sendSMS(tpl, message){
    if(!message){
      message = this.buildMessage(tpl);
    }
    const mobile = '+687' + this.customer.mobile.toString() ;
    
    const task = {
      client: `${this.customer.firstName} ${this.customer.lastName}`,
      title: this.customer.title
    }
    let { objectId, alias, email, title} = this.customer.shop;    
    
    return Parse.Cloud.run('smsSend', { 
      date: this.date, 
      task, 
      smsm: message, 
      smsr:mobile, 
      order: this.order.id, 
      sentStatus: this.sentStatus, 
      shop: { objectId, alias, email, title } 
    })
  }
  
  async sendEmail(tpl){ 
    const text = this.buildMessage(tpl);
    //const {alias, title, email: from} = this.customer.shop;
    const {email: to, shop:{ objectId, alias, title, email: from}} = this.customer;
    
    return Parse.Cloud.run('mailSend', { 
      date: this.date, 
      order: this.order.id, 
      sentStatus: this.sentStatus,
      shopId: objectId,
      alias, title, from, to, text, 
      subject: `${this.customer.shop.title}: Commande à récupérer ${this.order.ticket}` 
    })
  }

  buildMessage(tpl){
    tpl = tpl.replace('[montant]', this.order.totalAmount ? currencyString(this.order.totalAmount) : '**') 
      .replace('[ticket]', this.order.ticket ? `#${this.order.ticket}` : '' )
      .replace('[prenom]', this.customer.firstName ? this.customer.firstName : '' ) 
      .replace('[nom]', this.customer.lastName ? this.customer.lastName : '' )
      .replace('[magasin]', this.customer.shop.title);
    return tpl; 
  }

  getSMS(order, state){
    this.customer = toJS( this.taskStore.findCustomerByOrder(order.id) );
    this.order = order;
    const messageTpl = toJS( this.shopStore.messageTpl );
    const smsTpl = messageTpl.find(tpl => tpl.type === `sms_${state}`);
    const message = this.buildMessage(smsTpl.content);
    return message;
  }

}

export default decorate( NotifStore, {
  sendSMS: action,
  sending: observable,
});
